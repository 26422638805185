<template>
  <van-pull-refresh style="overflow: scroll; height: 100%" v-model="refreshing" @refresh="onRefresh">
    <van-list
        v-model="isLoading"
        :finished="finished"
        :loading-text="$t('loading')"
        :finished-text="$t('no-more')"
        @load="onLoad"
    >
      <Item v-for="item in list" :key="item.value" :item="item" />
    </van-list>
  </van-pull-refresh>
</template>
<script>
import Item from "./Item.vue";
import {MerchantAdvertiseGet} from "../../api/httpApi";
import {mapGetters} from "vuex";
import {getAccounts} from "../../utils/wallet";
export default {
  name: "List",
  props: {
  },
  computed: {
    ...mapGetters(["protocol", "coinList"])
  },
  data() {
    return {
      isLoading: false,
      finished: false,
      refreshing: false,
      params: {
        Data: {
          Protocol: 0,
          Mold: 100,
          CoinId: 0,
          Nick: "",
          MinNum: 0,
          MinPrice: 0,
          MaxPrice: 0,
          CurrencyId: [],
          PayId: [],
          OrderBy: 0,
          OrderStatus: 100,
        },
        Page: 1,
        Size: 20
      },
      list: [],
    };
  },
  methods: {
    async onLoad() {
      const accounts = await getAccounts()
      this.params.Data.Nick = accounts[0]
      this.params.Data.Protocol = this.protocol
      const res = await MerchantAdvertiseGet(this.params)
      const list = res.data || []
      if (list.length < this.params.Size) {
        this.finished = true
      }
      if (this.params.Page === 1) {
        this.list = list
      } else {
        this.list = this.list.concat(list)
      }
      this.refreshing = false;
      this.params.Page++
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.params.Page = 1
      this.onLoad().then();
    },
    filterData(status, sort) {
      this.params.Data.OrderStatus = status
      this.params.Data.OrderBy = sort
      this.onRefresh()
    }
  },
  components: { Item },
};
</script>
<style>

</style>
