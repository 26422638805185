<template>
  <div class="box">
    <div
      class="container-flex-h tab-box"
    >
      <div class="container-flex-h-order tab-bg">
        <div
          class="tab-item"
          v-for="item in statusArr"
          :key="item.value"
          @click="tabClick(item.value)"
        >
          <p
            :style="{
              color:
                statusValue == item.value
                  ? '#0875C4'
                  : 'rgba(61, 61, 61, 0.5300)',
            }"
          >
            {{ $t(item.text) }}
          </p>
          <p :class="statusValue == item.value ? 'tab-item-select ' : ''"></p>
        </div>
      </div>
      <div class="container-flex-h-order">
        <div class="sort">
          <van-dropdown-menu>
            <van-dropdown-item @change="sortChange" v-model="sortValue" :options="sortArr" />
          </van-dropdown-menu>
        </div>
      </div>
    </div>

    <!-- content -->
    <SecondList class="list-box" ref="page" />
  </div>
</template>
<script>
// 我的广告列表
import SecondList from "./SecondList.vue";
import MySelected from "../../components/my-selected";
export default {
  name: "AdvertiseUserList",
  data() {
    return {
      sortValue: 1,
      sortArr: [
        { text: '价格降序', value: 1 },
        { text: '价格升序', value: 2 },
        { text: '时间降序', value: 3 },
        { text: '时间升序', value: 4 },
      ],
      statusValue: 100,
      statusArr: [
        { value: 100, text: "advertise-type-all" },
        { value: 1, text: "advertise-type-finish" },
        { value: 0, text: "advertise-type-ing" },
        { value: -1, text: "advertise-type-cancel" },
      ],
    };
  },
  methods: {
    sortChange(value) {
      if (this.sortValue === value) {
        return false
      }
      this.sortValue = value
      this.$refs.page.filterData(this.statusValue, this.sortValue)
    },
    tabClick(value) {
      if (this.statusValue === value) {
        return false
      }
      this.statusValue = value
      this.$refs.page.filterData(this.statusValue, this.sortValue)
    }
  },
  components: { SecondList,MySelected },
  mounted() {

  },
};
</script>
<style scoped>

.box {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tab-item {
  margin: auto 16px;
  font-size: 26px;
}

.tab-item-select {
  color: #0875c4;
  height: 3px;
  opacity: 1;
  background-color: #0875c4;
}

.tab-box {
  /*margin-top: 24px;*/
  /*border-top: 2px solid #dcdee0;*/
  border-bottom: 2px solid #dcdee0;
  padding: 0 24px;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.tab-bg {
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.tab-bg::-webkit-scrollbar {
  display: none;
}

.list-box {
  background-color: #F2F2F2;
}

</style>
