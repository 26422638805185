<template>
  <div class="box">
    <div class="header">
      <div class="header-left">
        <div class="header-left-text">{{ $t("advertise-number") }}：</div>
        <div class="header-left-value">{{item.AdId}}</div>
      </div>
      <div class="header-right">
        {{ item.Mold === 0 ? '卖出单' : '收购单' }}
      </div>
    </div>
    <div class="line"></div>

    <div class="body">

      <div class="body-item body-item-m33">
        <div class="item-pay-list">
          <div class="item-pay-list-item" v-for="p in item.PaymentInfo" :key="p.Id">
            <span class="item-pay-list-item-line" :style="{color: p.Color}"></span>
            <span>{{p.EnName}}</span>
          </div>
        </div>
        <div class="item-status" :class="statusClass">
          {{statusText}}
        </div>
      </div>

      <div class="body-item-ii">
        <div class="body-item-text">{{ $t("remain-num") }}</div>
        <div class="body-item-value">{{item.Num - item.Deal - item.Pending}} {{item.CoinName}}</div>
      </div>
      <div class="body-item-ii">
        <div class="body-item-text">{{ $t("limit-unit") }}</div>
        <div class="body-item-value">{{item.Min <= 0 ? '不限额' : item.Min}} ~ {{item.Max <= 0 ? '不限额' : item.Max}}</div>
      </div>
      <div class="body-item-ii">
        <div class="body-item-text">{{ $t("sell-price") }}</div>
        <div class="body-item-value">{{item.Price}} {{item.CurrencyInfo.EnName}}</div>
      </div>

      <div class="body-item">
        <div class="body-item-ii">
          <div class="body-item-text">{{ $t("deal-amount") }}</div>
          <div class="body-item-value">{{DealMoney}} {{item.CurrencyInfo.EnName}}</div>
        </div>

        <van-button
            class="body-item-btn"
            success
            @click="action"
            :loading="loading"
            color="#3665B7"
        >{{ $t("to-advertise-detail") }}</van-button>
      </div>

    </div>
  </div>
</template>
<script>
import BigNumber from "bignumber.js";

export default {
  name: "Item",
  props: {
    item: {
      type: Object,
      default() {
        return {
          Status: 0,
          Num: 0,
          Deal: 0,
          Price: 0,
          CurrencyInfo: {}
        }
      }
    },
  },
  computed: {
    DealMoney() {
      return new BigNumber(this.item.Deal).times(new BigNumber(this.item.Price)).decimalPlaces(2)
    },
    statusClass() {
      const statusArr = {
        "-1": "item-status-cancel",
        0: "",
        1: "item-status-finish",
      }
      return statusArr[this.item.OrderStatus]
    },
    statusText() {
      const statusArr = {
        "-1": "已取消",
        0: "进行中",
        1: "已完成",
      }
      return statusArr[this.item.OrderStatus]
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    action() {
      this.$router.push({ path: `/order-user?adId=${this.item.AdId}&source=trader` });
    },
  },
};
</script>
<style scoped>
.box {
  padding: 32px 40px 40px;
  background-color: #FFFFFF;
  margin-bottom: 10px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-right {
  color: #999999;
}

.header-left-text {
  color: #999999;
}

.header-left-value {
  font-size: 26px;
  color: #333333;
  font-weight: bold;
}

.line {
  background: linear-gradient(
      to left,
      transparent 0%,
      transparent 50%,
      #ccc 50%,
      #ccc 100%
  );
  height: 2px;
  margin-top: 20px;
  background-size: 10px 1px;
  background-repeat: repeat-x;
}

.body {
  margin-top: 12px;
}

.body-item {
  display: flex;
  justify-content: space-between;
}

.body-item-ii {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.body-item-m33 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.item-pay-list {
  color: #333333;
  font-size: 24px;
  display: flex;
  align-items: center;
}

.item-pay-list-item {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.item-pay-list-item-line {
//margin-top: 2px;
  width: 3px;
  height: 22px;
  background-color: #03F474;
  margin-right: 10px;
}

.item-status {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  color: #E3B94B;
  font-size: 30px;
  font-weight: bold;
  width: 181px;
  height: 59px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../assets/img/order-ing.png");
}

.item-status-cancel {
  color: #E75D4F;
  background-image: url("../../assets/img/order-cancel.png");
}

.item-status-finish {
  color: #3DC799;
  background-image: url("../../assets/img/order-finish.png");
}

.body-item-text {
  color: #B3B3B3;
  font-size: 24px;
  margin-right: 20px;
  /*width: 96px;*/
  text-align: justify;
  text-align-last: justify;
}

.body-item-value {
  font-size: 24px;
  color: #333333;
}

.body-item-btn {
  font-size: 24px;
  height: 60px;
  border-radius: 8px
}

</style>
